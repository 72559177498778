<script setup lang="ts">
import { useCyclePeriods } from '~/features/cycles';

const isHowCyclesWorkModalOpen = ref(false);
const { canSeeReoccurringTokens, canSeeRewardSystem } = useUserState();
const { isOrderWindowOpen, timeUntilOrderWindowOpens } = useCyclePeriods();

defineProps<{
  hasCloseButton?: boolean;
}>();

defineEmits(['close']);
</script>

<template>
  <div v-if="!isOrderWindowOpen">
    <WalletHowCyclesWorkModal v-model="isHowCyclesWorkModalOpen" />
    <div
      class="absolute left-0 top-0 z-[500] flex h-full w-full items-center justify-center bg-white/70 backdrop-blur-[2px]"
    >
      <div
        class="relative w-[90%] rounded-xl border border-b-[3px] border-black bg-green-100 px-6 py-6 text-center text-sm"
      >
        <div v-if="hasCloseButton" class="absolute right-3 top-3">
          <button
            type="button"
            class="flex size-[24px] items-center justify-center rounded-full border border-b-[3px] border-black bg-white text-lg leading-none text-black transition-colors hover:border-b-[1px] hover:bg-gray-50"
            @click="$emit('close')"
          >
            <UIcon name="i-heroicons-x-mark" />
          </button>
        </div>
        <span class="mb-5 block text-[40px] leading-none">⏳</span>
        <TnHeading
          :ui="{
            wrapper: '!text-3xl text-center mx-auto uppercase text-white',
          }"
        >
          {{ timeUntilOrderWindowOpens }} Until Sampling Begins
        </TnHeading>

        <slot name="description" :time="timeUntilOrderWindowOpens">
          <p class="my-5 block">
            <span v-if="canSeeReoccurringTokens">
              We love your excitement and can't wait to show you our products
              for next month.
            </span>
            <span v-else-if="canSeeRewardSystem">
              We love your excitement and can't wait to show you our products
              for next month. Join hundreds of other Taste Network users and
              earn some points!
            </span>
          </p>
        </slot>

        <div class="flex flex-col gap-2">
          <slot name="footer">
            <UButton
              block
              color="tertiary"
              icon="i-heroicons-calendar"
              @click="isHowCyclesWorkModalOpen = true"
            >
              How Cycles Work
            </UButton>
            <UButton block color="secondary" @click="$emit('close')"
              >Go Back</UButton
            >
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
